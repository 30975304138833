<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col cols="7">
        <v-data-table
          must-sort
          fixed-header
          item-key="uuid"
          :loading="loading.patients"
          :headers="headers"
          :items="patients.data"
          :options.sync="options"
          :server-items-length="patients.total"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [ 10, 25, 75, 150 ],
            itemsPerPageText: 'Patients per page:'
          }"
          class="elevation-2 mt-3"
          @click:row="(item) => patient = item"
        >
          <template #top>
            <v-row class="px-3 mb-n3">
              <v-col v-if="$auth.check({ facilities: ['*', 'view', 'edit'] })">
                <facility-filter
                  :value.sync="options.filter.facility"
                  :params="{ has: 'prescriptions' }"
                />
              </v-col>
              <v-col xmd="2">
                <provider-filter
                  :value.sync="options.filter.provider"
                  :params="{ has: 'prescriptions' }"
                />
              </v-col>
              <v-col xmd="2">
                <drug-filter
                  :value.sync="options.filter.drug"
                  :params="{ has: 'prescriptions' }"
                />
              </v-col>
              <v-col xmd="2">
                <range-filter
                  :value.sync="options.filter.duration"
                  label="Duration"
                  item="day"
                  icon="fal fa-calendar-range"
                />
              </v-col>
              <v-col xmd="2">
                <table-filter
                  :value.sync="options.filter.route"
                  :choices="$routes"
                  type="routes"
                  label="Route"
                  icon="fal fa-syringe"
                  multiple
                />
              </v-col>
              <v-col xmd="2">
                <table-filter
                  :value.sync="options.filter.cost"
                  :choices="[
                    { value:1, text:'$' },
                    { value:2, text:'$$' },
                    { value:3, text:'$$$' },
                    { value:4, text:'$$$$' },
                  ]"
                  type="cost"
                  label="Cost"
                  icon="fal fa-dollar"
                  small-chips
                  multiple
                />
              </v-col>
              <v-col xmd="2">
                <table-filter
                  :value.sync="options.filter.flags"
                  :choices="$flags"
                  type="flags"
                  label="Flags"
                  icon="fal fa-flag"
                  multiple
                />
              </v-col>
              <v-col cols="auto">
                <v-btn
                  icon
                  tile
                  @click="print"
                >
                  <v-icon
                    color="red"
                  >
                    fa-thin fa-file-pdf
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </template>
          <template #item.dob="{ item }">
            {{ item.dob | moment('L') }}
          </template>
          <template #item.first_name="{ item }">
            <patient-name
              :patient="item"
              format="first_name"
            />
          </template>
          <template #item.last_name="{ item }">
            <patient-name
              :patient="item"
              format="last_name"
            />
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title v-if="patient.last_name">
            <patient-name
              :patient="patient"
              format="last_first"
            />
            <v-spacer />
            <v-btn
              icon
              tile
              @click="print(patient)"
            >
              <v-icon color="red">
                fa-thin fa-file-pdf
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title v-else>
            Select a patient to the left
          </v-card-title>
          <v-card-subtitle>
            {{ patient.mrn }}
          </v-card-subtitle>
          <v-list
            class="py-0"
          >
            <template
              v-for="(prescription, index) in patient.prescriptions"
            >
              <v-divider
                :key="'divider-' + prescription.uuid"
              />
              <v-list-group
                :key="'prescription-' + prescription.uuid"
                :value="index < 1"
                :append-icon="null"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ prescription.drug.name.en }}<br>
                      {{ prescription.dosage }}
                      {{ prescription.route }}
                      {{ prescription.interval }}
                      <drug-price
                        v-if="prescription.cost"
                        :key="`drug-price-${prescription.cost}`"
                        :price="prescription.cost"
                      />
                    </v-list-item-title>
                    <v-list-item-subtitle class="pt-1 text-wrap">
                      <v-icon
                        class="mt-n1"
                        small
                      >
                        far fa-user-md
                      </v-icon>
                      {{ `${prescription.provider.last_name}, ${prescription.provider.first_name}` }}
                      <template
                        v-for="(assigned_provider, i) in prescription.assigned_providers"
                      >
                        <v-icon
                          :key="i"
                          x-small
                        >
                          fal fa-arrow-right
                        </v-icon>
                        {{ `${assigned_provider.last_name}, ${assigned_provider.first_name}` }}
                      </template>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="pt-1 mb-2">
                      <v-icon
                        class="mt-n1"
                        small
                      >
                        far fa-calendar-week
                      </v-icon>
                      {{ prescription.start_date | moment('L') }} -
                      <template
                        v-if="prescription.end_date"
                      >
                        {{ prescription.end_date | moment('L') }}
                      </template>
                      <template v-else>
                        ?
                      </template>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <warning-chip
                        v-for="flag in prescription.flags"
                        :key="'asp_chip_' + flag"
                        :flag="flag"
                        x-small
                        class="mr-1"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="$auth.check({ prescriptions: 'reassign' })"
                  >
                    <v-menu
                      xbottom
                      xright
                      offset-y
                    >
                      <template #activator="{ on }">
                        <v-btn
                          small
                          ripple
                          icon
                          v-on="on"
                        >
                          <v-icon>fal fa-ellipsis-v</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-dialog
                          v-model="changeProviderDialog.open"
                          width="350"
                        >
                          <template #activator="{ on }">
                            <v-list-item
                              v-on="on"
                            >
                              <v-list-item-action>
                                <v-icon
                                  small
                                  class="mr-n4"
                                >
                                  fal fa-user-edit fa-fw
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content><v-list-item-title>Change Provider</v-list-item-title></v-list-item-content>
                            </v-list-item>
                          </template>
                          <v-card>
                            <v-card-title>
                              Change Provider
                            </v-card-title>
                            <v-divider />
                            <v-card-text
                              class="pt-2 pb-0"
                            >
                              All Precision ASP alerts related to patient {{ patient.first_name }} {{ patient.last_name }}, that were attributed to
                              {{ prescription.provider.first_name }} {{ prescription.provider.last_name }}, will now be sent to the following provider:
                              <provider-filter
                                key="provider-select"
                                :value.sync="changeProviderDialog.provider"
                                :multiple="false"
                                label="Select Provider"
                                :item-disabled="(item) => {
                                  return item.uuid == prescription.provider.uuid
                                }"
                              />
                            </v-card-text>
                            <v-divider />
                            <v-card-actions>
                              <v-spacer />
                              <v-btn
                                text
                                @click="changeProviderDialog.open = false"
                              >
                                {{ $t('Cancel') }}
                              </v-btn>
                              <v-btn
                                :loading="changeProviderDialog.loading"
                                color="primary"
                                @click="updateProvider(prescription)"
                              >
                                {{ $t('Save') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </template>
                <template #xappendIcon>
                  <arkscore-gauge
                    :key="'arkscore-gauge-' + prescription.drug.score_adverse_reaction"
                    :value="prescription.drug.score_adverse_reaction"
                    :size="30"
                    right
                  />
                </template>
                <v-list-item
                  v-for="(flag, index) in prescription.flags"
                  :key="index"
                  two-line
                >
                  <v-list-item-icon
                    class="mb-1 mr-3"
                  >
                    <warning-chip
                      :key="'asp_dot_' + flag"
                      :flag="flag"
                      small
                      dot
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      class="text-wrap"
                      v-text="message(flag, prescription)"
                    />
                  </v-list-item-content>
                </v-list-item>
                <arkscore-card
                  :key="'arkscore-card-' + prescription.drug.score_adverse_reaction"
                  :name="$t('arkscore.adverse_reaction')"
                  :score="prescription.drug.score_adverse_reaction"
                  blurb="The likelihood of the drug to cause adverse effects to the patient."
                  :bar-height="12"
                  flat
                />
              </v-list-group>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue2Filters from "vue2-filters";

export default {
  name: "PrecisionASP",
  metaInfo() {
    return {
      title: 'PrecisionASP',
    };
  },
  components: {
    WarningChip: () => import('@/components/asp/WarningChip.vue'),
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
    ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
    DrugFilter: () => import('@/components/tables/filters/DrugFilter.vue'),
    ProviderFilter: () => import('@/components/tables/filters/ProviderFilter.vue'),
    FacilityFilter: () => import('@/components/tables/filters/FacilityFilter.vue'),
    RangeFilter: () => import('@/components/tables/filters/RangeFilter.vue'),
    TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
    DrugPrice: () => import('@/components/drug/DrugPrice.vue'),
    PatientName: () => import('@/components/patient/PatientName.vue'),
    // NotMyPatient: () => import('@/components/asp/NotMyPatient.vue'),
    // pdf: () => import('vue-pdf'),
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      icon: "/img/arkstone-logo-icon.svg",
      loading: {
        patients: false,
        providers: false,
      },
      changeProviderDialog: {
        open: false,
        loading: false,
        provider: null,
      },
      patients: {},
      patient: {},
      providers: [],
      options: {
        search: null,
        filter: this.$route.query,
        sortBy: ['last_name'],
        sortDesc: [false],
      },
      headers: [
        {
          text: 'Account',
          value: 'mrn',
        },
        {
          text: this.$t('last name'),
          value: 'last_name',
        },
        {
          text: this.$t('first name'),
          value: 'first_name',
        },
        // {
        //   text: 'Gender',
        //   value: 'gender',
        // },
        {
          text: 'D.O.B.',
          value: 'dob',
        },
        {
          text: '# Rx',
          value: 'prescriptions_count',
          align: 'right',
        },
      ],
    }
  },
  computed: {
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.fetchPatients()
      },
    },
  },
  created () {
    this.fetchPatients()
  },
  methods: {
    fetchPatients () {
      this.loading.patients = true
      const promise = this.axios.get('/asp', {
        params:
          {
            ...{
            page: this.options.page,
            count: this.options.itemsPerPage,
            sort: this.options.sortBy[0],
            order: this.options.sortDesc[0] ? 'desc' : 'asc',
            search: this.options.search,
          },
            ...this.$route.query,
          },
      })

      return promise.then((response) => {
        this.patients = response.data
      })
        .catch(error => {
          if (error.response.status == 404 || error.response.status == 422) {
            this.error_count++
            this.error = true
            this.error_message = 'The information you entered is incorrect. Try again.'
          } else if (error.response.data?.message) {
            this.$toast.error(error.response.data.message)
          } else {
            this.$toast.error(error)
          }
        })
        .finally(() => { this.loading.patients = false })
    },
    message (flag, prescription) {
      var message = ''
      switch (flag) {
        case 'no_end_date':
          message = 'No end date has been specified for this prescription. Consider antibiotic timeout and re-evaluation of antibiotic necessity.'
          break
        case 'over_3_days':
          var passed = this.$moment().diff(prescription.start_date, 'days') + 1
          if (passed) {
            message = 'Today is day ' + passed + ' of '
          } else {
            message = 'This is '
          }
          message += 'a ' + (this.$moment(prescription.end_date).diff(prescription.start_date, 'days') + 1) + ' day prescription. Consider antibiotic timeout and re-evaluation of antibiotic necessity.'
          break
        case 'broad_spectrum':
          message = 'As a broad spectrum antibiotic, ' + prescription.drug.name.en + ' is typically used empirically. If possible, de-escalation to a more narrow spectrum antimicrobial is recommended.'
          break
        case 'risk_c_diff':
          message = prescription.drug.name.en + ' may be associated with increased risks for the acquisition of C. diff. If possible, consider antibiotic de-escalation, or discontinuation.'
          break
        case 'second_line':
          message = prescription.drug.name.en + ' is typically considered in specific circumstances and not typically used for common infections'
          break
        case 'lab_monitoring':
          message = prescription.drug.name.en + ' may require lab monitoring to assure adequate drug concentrations or levels. In addition, lab monitoring may be needed to assure there is no risk to the patient.'
          if (prescription.drug.renal_dosing || prescription.drug.hepatic_dosing) {
            var adjustment = []
            message += ' Also, '
            if (prescription.drug.renal_dosing) {
              adjustment.push(' renal ')
            }
            if (prescription.drug.hepatic_dosing) {
              adjustment.push(' hepatic ')
            }
            message += adjustment.join(' and/or ') + ' dosing adjustments may be necessary.'
          }
          break
        case 'caution':
          message = prescription.drug.name.en + ' may be associated with adverse drug reactions to the patient. If possible, consider an alternative treatment regimen.'
          break
        case 'fda_warnings':
          message = 'The FDA has issued warnings for ' + prescription.drug.name.en + '. Therefore, consider an alternative antimicrobial if possible, to reduce possible risk of adverse reactions.'
          break
        default:
          message = flag
      }
      return message
    },
    updateProvider(prescription) {
      this.changeProviderDialog.loading = true
      this.axios.post('prescriptions/reassign', {
        prescription: prescription.uuid,
        provider: this.changeProviderDialog.provider,
      })
        .then(() => {
          this.fetchPatients
          this.changeProviderDialog.open = false
          this.changeProviderDialog.provider = null
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.changeProviderDialog.loading = false
        })
    },
    print (patient = null) {
      this.axios({
          url: 'reports/asp_list/pdf',
          data: { patient: patient.uuid, filter: this.options.filter },
          method: 'POST',
          responseType: 'blob',
      })
        .then((response) => {
          var filename = patient.first_name ? `${patient.first_name} ${patient.last_name} - ` : ''
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', `${filename}Precision ASP - ${this.$moment().format('M.D.Y')}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          this.$toast.error(error)
        })

    },
  },
};
</script>
<style lang="scss" scoped>
.branded {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1em;
}
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
.v-card {
  display: flex !important;
  flex-direction: column;
  max-height:calc(100vh - 124px) !important;

  .v-list {
    flex-grow: 1;
    overflow: auto;
  }
}
</style>
<style>
.v-data-table__wrapper {
    max-height:calc(100vh - 244px) !important;
}
</style>
